import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'antd/lib/avatar';
import { initials } from 'common/utils/string';
import lodashGet from 'lodash.get';
import styled from 'styled-components';
import withUserInfo from 'common/hoc/withUserInfo';
import frame from 'components/common/avatar/frame.png';

const AvatarVip = styled.div`
  &.premium {
    padding: 2px 5px;
    position: relative;
    display: inline-block;
    :after {
      content: '';
      position: absolute;
      background: url(${frame});
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-size: 100% 100%;
    }
  }
`;

const loadImage = (url, callback) => {
  const img = new Image();
  img.onload = () => callback(true);
  img.onerror = () => callback(false);
  img.src = url;
};

const AvatarImage = ({
  user = {},
  size,
  style,
  className,
  title,
  alt,
  shape,
  userInfo,
}) => {
  const [avatarSrc, setAvatarSrc] = React.useState(false);
  const isPremium =
    lodashGet(userInfo, 'latest_order_expire.ts') > Date.now() / 1000 &&
    user.iid === userInfo.iid;

  // if the image is loadable, this will be used as the avatar src
  const mayBeAvatarSrc = lodashGet(user, 'avatar');

  React.useEffect(
    () => {
      if (mayBeAvatarSrc) {
        loadImage(mayBeAvatarSrc, (success) => {
          if (success) {
            setAvatarSrc(mayBeAvatarSrc);
          } else {
            setAvatarSrc(null);
          }
        });
      }
    },
    [mayBeAvatarSrc],
  );

  style = Object.assign({}, style, {
    verticalAlign: 'middle',
  });

  const renderProps = { size, className, title, alt, style, shape };

  let avatarComp = (
    <Avatar title={user.name} {...renderProps}>
      {initials(user.name)}
    </Avatar>
  );

  if (!user || !user.name) {
    avatarComp = <Avatar {...renderProps} icon="user" />;
  }

  if (avatarSrc && mayBeAvatarSrc) {
    avatarComp = <Avatar title={user.name} {...renderProps} src={avatarSrc} />;
  }

  return (
    <AvatarVip className={isPremium ? 'premium' : ''}>{avatarComp}</AvatarVip>
  );
};

AvatarImage.propTypes = {
  user: PropTypes.shape(),
};

AvatarImage.defaultProps = {
  user: null,
};

export default withUserInfo(AvatarImage);
