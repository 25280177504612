import { fork } from 'redux-saga/effects';
import changeStatus from './card/changeStatus';
import loginAsSaga from 'sagas/auth/login-as';
import getTotalUnrepliedCommentSaga from './comment';

const adminSagas = [
  fork(changeStatus),
  fork(loginAsSaga),
  fork(getTotalUnrepliedCommentSaga),
];

export default adminSagas;
