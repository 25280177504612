import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isQuestionDone } from 'common/question';
import { groupQuestions, isIntroSticky } from 'common/learn/Question';
import { types } from 'components/admin/question/schema/question-types';
import Icon from 'components/common/Icon';
import { getThemeConfigSelector } from 'utils/selector';
import { layouts } from 'configs/constants';
import QuestionNavigationHelp from './QuestionNavigationHelp';
import { t1 } from 'translate';
import './NormalExerciseControlQuestionGroups.scss';

class NormalExerciseControlQuestionGroups extends React.Component {
  getQuestionCSSClassModifier = (question, progresses = {}) => {
    const {
      answer,
      shouldDisplayCheckedResult,
      isTicked,
      isTouched,
      type,
      isCorrect,
    } = question || {};
    if (isTicked) {
      return 'ticked';
    }

    if (
      question.iid &&
      progresses[question.iid] &&
      progresses[question.iid].tts
    ) {
      return 'ordered';
    }

    if (
      shouldDisplayCheckedResult ||
      (question.type === types.TYPE_API && typeof isCorrect !== 'undefined')
    ) {
      return isCorrect ? 'correct' : 'incorrect';
    }

    if (isTouched && isQuestionDone(type, answer)) {
      return 'done';
    }
    return isTouched ? 'touched' : '';
  };

  getQuestionPositionInControl = (questionUniqueId, questionGroups) => {
    let questionIndex = -1;

    const groupIndex =
      Array.isArray(questionGroups) &&
      questionGroups.findIndex((group) => {
        questionIndex =
          Array.isArray(group) &&
          group.findIndex(
            (questionInGroup) =>
              questionInGroup && questionInGroup.uniqueId === questionUniqueId,
          );
        return questionIndex !== -1;
      });

    return {
      groupIndex,
      questionIndex,
    };
  };

  render() {
    const {
      currentListeningQuestionUniqueId,
      currentQuestionUniqueId,
      isQuestionClickable,
      onQuestionClick,
      progresses,
      type,
      themeConfig,
    } = this.props;

    let { questions } = this.props;

    if (!Array.isArray(questions)) {
      return null;
    }

    // filter intro sticky questions out, we will not show them in control bar
    questions = questions.filter(
      (question) => question && !isIntroSticky(question),
    );

    const questionGroups = groupQuestions(questions);
    const currentPositionInControl = this.getQuestionPositionInControl(
      currentQuestionUniqueId,
      questionGroups,
    );
    const currentGroupIndex = currentPositionInControl.groupIndex;

    let autoGeneratedQuestionNumber = 0;
    const cssClass = `${
      type === 'exam' ? 'exam' : 'normal'
    }-exercise-control-question-groups`;

    return (
      <React.Fragment>
        <div className={cssClass}>
          {Array.isArray(questionGroups) &&
            questionGroups.map((group, groupIndex) => {
              if (!Array.isArray(group)) {
                return null;
              }

              const isSingleGroup = group.length === 1;

              return (
                <div
                  className={`${cssClass}__group\
                   ${
                     currentGroupIndex === groupIndex
                       ? `${cssClass}__group--active`
                       : ''
                   }\
                   ${isSingleGroup ? `${cssClass}__group--single` : ''}`}
                  key={groupIndex}
                >
                  {group.map((question, questionIndex) => {
                    if (!question) {
                      return null;
                    }

                    const isQuestionClickableBoolean =
                      typeof isQuestionClickable === 'function'
                        ? isQuestionClickable(question)
                        : true;

                    const trackerStatus = this.getQuestionCSSClassModifier(
                      question,
                      progresses,
                    );

                    return (
                      <button
                        key={`${groupIndex}--${questionIndex}`}
                        className={`${cssClass}__question\
                            ${
                              question.uniqueId === currentQuestionUniqueId
                                ? `${cssClass}__question--current`
                                : ''
                            }\
                            ${cssClass}__question--${trackerStatus}\
                            ${
                              question.uniqueId ===
                              currentListeningQuestionUniqueId
                                ? `${cssClass}__question--listening`
                                : ''
                            }\
                            ${
                              isQuestionClickableBoolean
                                ? `${cssClass}__question--clickable`
                                : ''
                            }\
                            ${
                              isSingleGroup
                                ? `${cssClass}__question--single`
                                : ''
                            }\
                            `}
                        onClick={() => {
                          if (
                            isQuestionClickableBoolean &&
                            typeof onQuestionClick === 'function'
                          ) {
                            onQuestionClick(question);
                          }
                        }}
                      >
                        {question.type === types.TYPE_INTRODUCTION ? (
                          <Icon
                            icon="introduction_question"
                            title={t1('introduction_question')}
                            style={{ height: '18px', minHeight: '18px' }}
                          />
                        ) : (
                          (() => {
                            if (question.number) {
                              return question.number;
                            }
                            autoGeneratedQuestionNumber += 1;
                            return autoGeneratedQuestionNumber;
                          })()
                        )}
                      </button>
                    );
                  })}
                </div>
              );
            })}
          {/* only Pixelz needs explanation for types of questions */
          themeConfig.layout === layouts.PIXELZ && type !== 'exam' && (
            <QuestionNavigationHelp cssClass={cssClass} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

NormalExerciseControlQuestionGroups.propTypes = {
  currentListeningQuestionUniqueId: PropTypes.string,
  currentQuestionUniqueId: PropTypes.string,
  isQuestionClickable: PropTypes.func,
  onQuestionClick: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.shape()),
  progresses: PropTypes.shape(),
};

NormalExerciseControlQuestionGroups.defaultProps = {
  currentListeningQuestionUniqueId: null,
  currentQuestionUniqueId: null,
  isQuestionClickable: () => true,
  onQuestionClick: () => {},
  questions: [],
  progresses: {},
};

const mapStateToProps = (state) => {
  const themeConfig = getThemeConfigSelector(state);

  return {
    progresses: state.trackerProgress || {}, //TODO
    themeConfig,
  };
};
export default connect(mapStateToProps)(NormalExerciseControlQuestionGroups);
