import React, { Component } from 'react';
import { connect } from 'react-redux';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import EditSession from '../edit';
import { t1 } from 'translate';

class EditSessionButton extends Component {
  render() {
    const {
      session,
      formid,
      node = {},
      hasPermUpdate,
      userIid,
      teachers = [],
      defaultTeacher = [],
    } = this.props;
    if (!hasPermUpdate) {
      return null;
    }

    return (
      <DetailOnDialog
        renderPreview={({ showFull }) => (
          <Icon
            icon="edit"
            onClick={showFull}
            className="link"
            title={t1('edit')}
          />
        )}
        renderFull={() => (
          <EditSession
            searchFormId={formid}
            hiddenFields={{
              course_iid: node && node.iid,
              type: node && node.ntype === 'syllabus' ? 'plan' : '',
            }}
            node={session}
            userIid={userIid}
            teachers={teachers}
            defaultTeacher={defaultTeacher}
          />
        )}
        dialogOptionsProperties={{
          width: '70%',
          handleClose: true,
          title: `${t1('editing_session')}: ${node && node.name}`,
          className: 'modal-new-virtual-classes',
          modal: true,
        }}
        dialogKey="edit_session"
      />
    );
  }
}

export default connect()(EditSessionButton);
