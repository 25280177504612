import { t1 } from 'translate';
import lodashGet from 'lodash.get';

export const courseFilter = (courseList = [], includeAllOption) => {
  const options = courseList.map((course) => {
    let name = lodashGet(course, 'name');

    return {
      value: course.iid.toString(),
      label: name,
      primaryText: name,
    };
  });

  return {
    type: 'select',
    label: t1('select_course'),
    floatingLabelText: t1('select_course'),
    showSearch: true,
    options: [
      ...(includeAllOption
        ? [
            {
              value: '',
              label: t1('all_courses'),
              primaryText: t1('all_courses'),
            },
          ]
        : []),
      ...options,
    ],
  };
};
