import { call, put, takeEvery } from 'redux-saga/effects';
import Requester from 'common/network/http/Request';
import actions from 'actions/node/creators';
import {
  GET_TOTAL_UNREPLIED_COMMENT,
  setCountUnrepliedComment,
} from 'actions/admin/saga-creators';

function* getTotalUnrepliedComment() {
  try {
    const json = yield call(
      Requester.post,
      '/feedback/api/get-total-comments-not-reply',
    );

    if (json.success) {
      yield put(setCountUnrepliedComment(json.result));
    } else {
      yield put(actions.snackbar(true, json.message || json.err));
    }
  } catch (e) {
    console.log('Error get ', e);
  }
}

export default function* getTotalUnrepliedCommentSaga() {
  yield takeEvery(GET_TOTAL_UNREPLIED_COMMENT, getTotalUnrepliedComment);
}
