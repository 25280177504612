export const GET_TOTAL_UNREPLIED_COMMENT = 'GET_TOTAL_UNREPLIED_COMMENT';
export const SET_TOTAL_UNREPLIED_COMMENT = 'SET_TOTAL_UNREPLIED_COMMENT';

const adminSagaActions = {
  loginAs: (payload) => ({
    type: 'LOGIN_AS',
    payload, // could be user name, mail, iid
  }),
  synchronizeDataFromDatabaseToRedis: (url, params) => ({
    type: 'SYNCHRONIZE_DATA_FROM_DATABASE_TO_REDIS',
    url,
    params,
  }),
};

export const setCountUnrepliedComment = (numberOfUnrepliedComments = 0) => ({
  type: SET_TOTAL_UNREPLIED_COMMENT,
  numberOfUnrepliedComments,
});

export const getCountUnrepliedComment = () => ({
  type: GET_TOTAL_UNREPLIED_COMMENT,
});

export default adminSagaActions;
