import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate/index';
import Icon from 'components/common/Icon/index';
// import FormCreateSession from './Create';
import apiUrls from '../endpoints/index';
import Request from 'common/network/http/Request';
import { canJoinToLearn, isSessionScheduled, joinStatuses } from '../utils';
import lGet from 'lodash.get';
import AntButton from 'components/common/primary-button';
import { ERR_SESSION_NOT_STUDENT_OR_TEACHER_OF_COURSE } from './constants';
import withAutomaticScaleVirtualClass from 'components/admin/session/common/withAutomaticScaleVirtualClass';

class MeetingUrl extends Component {
  createBBBLink = (sessionIid) => {
    const params = {
      session_iid: sessionIid,
      is_course_admin: 1,
    };
    const { dispatch } = this.props;

    Request.post(apiUrls.join_class_by_session, params).then((res) => {
      if (res.success) {
        window.open(lGet(res.result, 'url', ''), '_blank');
        // const contentDialog = (
        //   <div>
        //     {t1('click_the_following_link_to_')}
        //     <a href={res.result} target="_blank">
        //       {res.result}
        //     </a>
        //   </div>
        // );
        //
        // const optionsProperties = {
        //   handleClose: true,
        //
        //   modal: true,
        //   title: t1('class_session_url'),
        // };
        // dispatch(
        //   actions.handleOpenDialog({ contentDialog, optionsProperties }),
        // );
      } else {
        if (res.err_code == ERR_SESSION_NOT_STUDENT_OR_TEACHER_OF_COURSE) {
          alert(
            'Bạn không phải là giảng viên hay học viên của lớp nên không thể join',
          );
        } else {
          alert(res.messsage || t1('get_virtual_class_failed'));
        }
      }
    });
  };

  render() {
    const {
      session,
      beforeTimeCanJoinClass,
      statusText,
      serverTimestamp,
      automaticScaleVirtualClassEnabled,
    } = this.props;

    if (!isSessionScheduled(session, automaticScaleVirtualClassEnabled)) {
      return <span>{t1('not_scheduled')}</span>;
    }

    const status = canJoinToLearn(
      session,
      beforeTimeCanJoinClass,
      serverTimestamp,
      automaticScaleVirtualClassEnabled,
    );

    return (
      <div>
        {status === joinStatuses.JOIN_STATUS_OK && (
          <AntButton
            className="status-item cursor-pointer"
            onClick={() => {
              this.createBBBLink(session.iid);
            }}
            icon="play-circle"
          >
            <div
              className="join-icon ve-enter"
              title={t1('get_new_link_to_join')}
            />
            <div>{statusText}</div>
          </AntButton>
        )}

        {status === joinStatuses.JOIN_STATUS_TOO_EARLY && (
          <div className="text-muted status-item">
            {t1('too_early_to_join')}.{' '}
            <span
              title={t1(
                'class_opens_%d_minutes_before_starting',
                session.pre_class_time || beforeTimeCanJoinClass,
              )}
            >
              <Icon icon="help" />
            </span>
          </div>
        )}

        {status === joinStatuses.JOIN_STATUS_TOO_LATE && (
          <div className="status-item">
            <span>{t1('time_over')}</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    beforeTimeCanJoinClass: lGet(
      state,
      'domainInfo.conf.before_time_can_join_class',
    ),
  };
};

export default connect(mapStateToProps)(
  withAutomaticScaleVirtualClass(MeetingUrl),
);
