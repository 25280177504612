import React from 'react';
import { canJoinToLearn, joinStatuses } from '../utils';
import MeetingUrl from './MeetingUrl';
import { t1 } from 'translate';
import Tag from 'antd/lib/tag';

const Status = ({ session, beforeCanJoinToLearn, serverTimestamp }) => {
  let cssClass, statusText, tag;

  const status = canJoinToLearn(session, beforeCanJoinToLearn, serverTimestamp);
  switch (status) {
    case joinStatuses.JOIN_STATUS_OK:
      statusText = t1('ongoing'); //'Đang học';
      break;
    case joinStatuses.JOIN_STATUS_TOO_LATE:
      cssClass = 'session-ended ve-daketthuc';
      statusText = t1('finished'); //'Đã kết thúc';
      tag = '#87d068';
      break;
    case joinStatuses.JOIN_STATUS_TOO_EARLY:
      cssClass = 'session-too-early ve-chuadengio';
      statusText = t1('upcoming');
      tag = '';
      // statusText = 'Sắp diễn ra';
      break;
    case joinStatuses.JOIN_STATUS_NOT_YET_SCHEDULED:
      statusText = t1('not_yet_scheduled'); // Chưa xếp lớp';
      break;
  }

  return (
    <div className="color-by-status">
      {status === joinStatuses.JOIN_STATUS_OK ? (
        <MeetingUrl
          session={session}
          statusText={statusText}
          serverTimestamp={serverTimestamp}
        />
      ) : (
        <div className="status-item">
          {!!cssClass && <div className={`join-icon ${cssClass}`} />}
          <Tag color={tag}>{statusText}</Tag>
        </div>
      )}
    </div>
  );
};

export default Status;
