import DatePicker from 'schema-form/elements/date-picker';
import { t1, t } from 'translate';
import Duration from 'schema-form/elements/duration/smaller-than-one-day';
import { durationDisplayFormats } from 'schema-form/elements/duration/smaller-than-one-day/common/constants';
import lodashGet from 'lodash.get';
import { required } from 'common/validators';
import React from 'react';

const date = new Date();
const defaultStartDate = Math.round(date.getTime() / 1000);
const numberClassHourDefault = 1;
const durationOfClassHourDefault = 60;
const startTime = date.getHours() * 60 + date.getMinutes();

const schema = (teachers, defaultTeacher = [], mode) => (
  formid,
  values,
  step,
  xpath,
  props,
  domainInfo,
) => {
  const numberClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.number_class_hour_default',
    ) || numberClassHourDefault;

  const durationOfClassHour =
    lodashGet(
      domainInfo,
      'conf.create_session_default_values.duration_of_class_hour_default',
    ) || durationOfClassHourDefault;

  const learnDuration = numberClassHour * durationOfClassHour;

  return {
    date_time: {
      type: DatePicker,
      getStartDate: true,
      hintText: t1('date'),
      floatingLabelText: t1('date'),
      fullWidth: true,
      defaultValue: mode === 'new' ? defaultStartDate : null,
    },
    start_time: {
      type: Duration,
      valueIsNumberOfMinutes: true,
      label: `${t1('start_time')} (${t('hh:mm')})`, //Giờ học: Từ (giờ: phút)
      formatTime: durationDisplayFormats.TIME_FORMAT, //default format,
      defaultValue: mode === 'new' ? startTime : '',
      fullWidth: true,
    },
    end_time: {
      type: Duration,
      valueIsNumberOfMinutes: true,
      label: `${t1('end_time')} (${t('hh:mm')})`, //Giờ học: Từ (giờ: phút)
      formatTime: durationDisplayFormats.TIME_FORMAT, //default format
      fullWidth: true,
      defaultValue: mode === 'new' ? startTime + learnDuration : '',
    },
    room_iid: {
      type: 'select',
      floatingLabelText: t1('virtual_class_room'),
      options: 'async',
      paramsasync: {
        __url__: '/venue/api/get-all-rooms?is_virtual=1',
        key: `room_iid_${values.location}`,
        value: 'iid',
        transformData: (data) => {
          if (!Array.isArray(data) || !data.length) {
            return [];
          }
          return data.map((item) => ({
            value: item.iid,
            label: lodashGet(item, 'full_name'),
            primaryText: lodashGet(item, 'full_name'),
          }));
        },
      },
      populateValue: mode === 'new',
    },
    teacher_iids: {
      type: 'select',
      multiple: true,
      floatingLabelText: t1('instructor_in_charge'), // 'Giảng viên',
      options: teachers.map((teacher) => ({
        value: teacher.iid,
        label: teacher.name,
        primaryText: teacher.name,
      })),
      defaultValue: mode === 'new' ? defaultTeacher.map(({ iid }) => iid) : [],
      key: `teacher_iids_${defaultTeacher.length}`,
      guide: t1('instructors_must_be_added_to_course_as_staff_first'),
    },
    learn_duration: {
      type: 'number',
      defaultValue: learnDuration,
      // floatingLabelText: 'Thời lượng (phút)',
      floatingLabelText: `${t1('duration')} (${t('minute')})`,
      fullWidth: true,
      validate: [required(t1('session_duration_cannot_be_empty'))],
    },
  };
};

const ui = (automaticScaleVirtualClassEnabled) => {
  const fields = [
    'date_time',
    'start_time',
    'end_time',
    'teacher_iids',
    'learn_duration',
  ];

  if (!automaticScaleVirtualClassEnabled) {
    fields.push('room_iid');
  }

  return [
    {
      id: 'scheduled-session',
      title: t1('session_scheduling'),
      fields,
    },
  ];
};

const LayoutFreeStyle = (props) => {
  const fieldNames = lodashGet(props, 'groups.scheduled-session.fieldNames');
  if (!fieldNames) {
    return null;
  }

  return (
    <div className="layout-new-session">
      <div className="row layout-new-session__content">
        <div className="col-md-3 date-time">{fieldNames.date_time}</div>

        <div className="col-md-6">
          <div className="row d-flex">
            <div className="col-xs-6 md-full-width">
              {fieldNames.start_time}
            </div>
            <span className="ve-arrrow-right icon-arrow" />
            <div className="col-xs-6 md-full-width">{fieldNames.end_time}</div>
          </div>
        </div>
        <div className="col-md-3">{fieldNames.learn_duration}</div>
      </div>

      <div>{fieldNames.room_iid}</div>
      <div>{fieldNames.teacher_iids}</div>
    </div>
  );
};

const layout = (step, values, xpath, props) => {
  return {
    component: LayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      props,
    },
  };
};

export default ({
  teachers,
  defaultTeacher = [],
  mode = 'new',
  automaticScaleVirtualClassEnabled,
}) => ({
  schema: schema(teachers, defaultTeacher, mode),
  ui: () => ui(automaticScaleVirtualClassEnabled),
  layout,
});
