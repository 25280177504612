import React, { useCallback, useState } from 'react';
import { t1 } from 'translate';
import { getDashboardUrl } from 'routes/links/common';
import AntIcon from 'antd/lib/icon';

const generatePublicSessionUrl = (sessionIid, publicCode) => {
  const host = window.location.origin;

  return `${host}${getDashboardUrl('sessions')}/${sessionIid}/${publicCode}`;
};

const PublicCode = ({ session }) => {
  const [copied, setCopied] = useState(false);
  const { enable_public, public_code, iid } = session;

  const copyToClipboard = useCallback(
    () => {
      if (copied) {
        return;
      }

      const publicSessionUrl = generatePublicSessionUrl(iid, public_code);

      const textField = document.createElement('textarea');
      textField.innerText = publicSessionUrl;
      document.body.appendChild(textField);
      textField.select();

      document.execCommand('copy');
      textField.remove();

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
    [public_code, iid, copied],
  );

  return (
    <div>
      {!enable_public || !public_code ? (
        <span>{t1('not_public')}</span>
      ) : (
        <>
          <strong>{public_code}</strong>{' '}
          <AntIcon
            type={`${copied ? 'check' : 'copy'}`}
            className={`${copied ? 'copied' : ''}`}
            title={`${copied ? 'Copied' : 'Copy'}`}
            onClick={copyToClipboard}
          />
        </>
      )}
    </div>
  );
};

export default PublicCode;
