import React from 'react';

export default {
  1: {
    id: 2,
    name: 'READING__DISPLAY_NAME_ANSWER_VNAME_MC',
    help_text: 'Chọn phương án đúng dịch nghĩa từ/cụm từ sau',
    skill: 'read',
    type: 'mc',
    display: 'name',
    answer: 'vname',
    questionDisplay: 'content',
    component: <div />,
  },
  2: {
    id: 2,
    name: 'READING__DISPLAY_NAME_ANSWER_AVATAR_MC',
    help_text: 'Chọn bức ảnh mô tả đúng từ/cụm từ sau',
    skill: 'read',
    type: 'mc',
    display: 'name',
    answer: 'avatar',
    questionDisplay: 'content',
    component: <div />,
  },
  3: {
    id: 3,
    name: 'TRANSLATE__DISPLAY_VNAME_ANSWER_NAME_MC',
    help_text: 'Chọn phương án đúng nghĩa từ/cụm từ sau',
    skill: 'translate',
    type: 'mc',
    display: 'vname',
    answer: 'name',
    questionDisplay: 'content',
    component: <div />,
  },
  4: {
    id: 4,
    name: 'TRANSLATE__DISPLAY_AVATAR_ANSWER_NAME_MC',
    help_text: 'Chọn phương án mô tả đúng bức ảnh',
    skill: 'translate',
    type: 'mc',
    display: 'avatar',
    answer: 'name',
    questionDisplay: 'avatar',
    component: <div />,
  },
  5: {
    id: 5,
    name: 'LISTENING__DISPLAY_PLAYER_ANSWER_NAME_MC',
    help_text: 'Chọn phương án đúng từ/cụm từ nghe được',
    skill: 'listen',
    type: 'mc',
    display: 'player',
    answer: 'name',
    questionDisplay: 'audio',
    component: <div />,
  },
  6: {
    id: 6,
    name: 'LISTENING__DISPLAY_PLAYER_ANSWER_VNAME_MC',
    help_text: 'Chọn phương án đúng dịch từ/cụm từ nghe được',
    skill: 'listen',
    type: 'mc',
    display: 'player',
    answer: 'vname',
    questionDisplay: 'audio',
    component: <div />,
  },
  7: {
    id: 7,
    name: 'LISTENING__DISPLAY_PLAYER_ANSWER_AVATAR_MC',
    help_text: 'Chọn bức ảnh mô tả đúng từ/cụm từ nghe được',
    skill: 'listen',
    type: 'mc',
    display: 'player',
    answer: 'avatar',
    questionDisplay: 'audio',
    component: <div />,
  },
  8: {
    id: 8,
    name: 'READING_TRANSLATE__DISPLAY_AVATAR_ANSWER_NAME_PAIR',
    help_text: 'AAAAAAAAAAAAAAAAA',
    skill: 'listen,translate',
    type: 'pair',
    display: 'avatar',
    questionDisplay: 'avatar',
    component: <div />,
  },
  9: {
    id: 9,
    name: 'READING_TRANSLATE__DISPLAY_VNAME_ANSWER_NAME_PAIR',
    help_text: ' AAAAAAAAAAAAAAAAAAA ',
    skill: 'listen,translate',
    type: 'pair',
    display: 'vname',
    questionDisplay: 'content',
    component: <div />,
  },
  10: {
    id: 10,
    name: 'TRANSLATE__REORDER',
    help_text: ' AAAAAAAAAAAAAAAAAAA ',
    skill: 'translate',
    type: 'reorder',
    display: 'name',
    questionDisplay: 'content',
    component: <div />,
  },
  11: {
    id: 11,
    name: 'SPELLING__DISPLAY_VNAME_ANSWER_NAME_INPUT',
    help_text: 'Dịch đúng từ/cụm từ sau',
    skill: 'spell',
    type: 'input',
    display: 'vname',
    answer: 'name',
    questionDisplay: 'content',
    component: <div />,
  },
  12: {
    id: 12,
    name: 'SPELLING__DISPLAY_SOUND_ANSWER_NAME_INPUT',
    help_text: 'Nhập từ/cụm từ nghe được',
    skill: 'spell',
    type: 'input',
    display: 'player',
    answer: 'name',
    questionDisplay: 'audio',
    component: <div />,
  },
  13: {
    id: 13,
    name: 'SPEAKING_PHONETICS__DISPLAY_PHONETICS_ANSWER_NAME_MC',
    help_text: ' AAAAAAAAAAAAAAAAAAA ',
    skill: 'phonetics,speak',
    type: 'mc',
    display: 'phonetics',
    answer: 'name',
    questionDisplay: 'content',
    component: <div />,
  },
  14: {
    id: 14,
    name: 'SPEAKING_PHONETICS__DISPLAY_PHONETICS_ANSWER_VNAME_MC',
    help_text: ' AAAAAAAAAAAAAAAAAAA ',
    skill: 'phonetics,speak',
    type: 'mc',
    display: 'phonetics',
    answer: 'vname',
    questionDisplay: 'content',
    component: <div />,
  },
  15: {
    id: 15,
    name: 'SPEAKING_PHONETICS__DISPLAY_NAME_ANSWER_PHONETICS_MC',
    help_text: ' AAAAAAAAAAAAAAAAAAA ',
    skill: 'phonetics,speak',
    type: 'mc',
    display: 'name',
    answer: 'phonetics',
    questionDisplay: 'content',
    component: <div />,
  },
  16: {
    id: 16,
    name: 'SPEAKING_PHONETICS__DISPLAY_PLAYER_ANSWER_PHONETICS_MC',
    help_text: ' AAAAAAAAAAAAAAAAAAA ',
    skill: 'phonetics,speak',
    type: 'mc',
    display: 'player',
    answer: 'phonetics',
    questionDisplay: 'audio',
    component: <div />,
  },
  17: {
    id: 17,
    name: 'FLASHCARD_DISLAY_NAME_PHONETIC_VNAME_AVATAR',
    help_text: 'FlashCard',
    skill: 'flashcard', //
    type: 'flashcard',
    display_type: 'flashcard',
    display: 'name',
    questionDisplay: '',
    component: <div />,
  },
  18: {
    id: 18,
    name: 'SPEECH_GOOGLE_DISLAY',
    help_text: 'Phát âm từ/cụm từ sau',
    skill: 'speakgoogle',
    type: 'speakgoogle',
    display_type: 'speakgoogle',
    display: 'name',
    answer: 'name',
    questionDisplay: '',
    component: <div />,
  },
};
