const urls = {
  get_learning_types: '/course/api/get-learning-types',
  export_score_of_courses_selected: '/course/data/export-score-of-courses',
  search_online_score: '/course/progress/search-online-score',
  score_by_rubric: '/course/progress/get-course-progress-by-rubrik',
  sync_course_progress_by_rubric:
    '/course/progress/sync-course-progress-by-rubrik',

  search_members: '/course/member/search',
  export_members: '/course/member/export',
  create_course: '/course/new',

  my_active_courses: '/student/api/get-student-active-courses',
  // TODO: why those 2 apis
  get_current_courses: '/student/api/get-current-courses',

  // enrolment
  move_students_to_other_course: '/course/enrol/move-students-to-other-course',
  get_course_list_to_move_students_to:
    '/course/api/get-other-course-list-to-move-to',

  // get_courses_to_register: '/course/api/get-courses-allowed-to-register',
  register_the_course: '/register-the-course',
  withdraw_the_course: '/withdraw-the-course',

  // student
  download_certificate: '/course/certificate/create-certificate',
};

export default urls;
