import PropTypes from 'prop-types';
import React from 'react';
import Button from 'antd/lib/button';
import { t1 } from 'translate';
import guideImage from 'components/admin/session/common/end-session/end-virtual-class-guide.gif';
import { canJoinToLearn, joinStatuses } from 'components/admin/session/utils';
import Modal from 'antd/lib/modal';
import { connect } from 'react-redux';
import sagaActions from 'actions/node/saga-creators';
import apiUrls from '../../endpoints';

function EndSession({
  session = {},
  beforeCanJoinToLearn,
  serverTimestamp,
  dispatch,
  searchFormId,
}) {
  if (!session.iid) return;
  const status = canJoinToLearn(session, beforeCanJoinToLearn, serverTimestamp);

  const handleDeleteMeeting = () => {
    dispatch(
      sagaActions.submitFormRequest('', {
        url: apiUrls.force_end_meeting,
        formidToSubmitOnSuccess: searchFormId,
        executeOnSuccess: Modal.destroyAll(),
        params: {
          session_iid: session.iid,
          session_id: session.id,
        },
      }),
    );
  };

  const showDeleteConfirm = () => {
    let secondsToGo = 5;
    const okText = (seconds) => `OK${seconds ? ` (${seconds})` : ''}`;

    const modal = Modal.confirm({
      title: t1('are_you_sure_to_delete_this_meeting?'),
      content: (
        <div>
          <div>{t1('make_sure_you_end_this_session_in_zoom_app')}</div>
          <img src={guideImage} alt="" className="m-t-5 border-round w-100" />
        </div>
      ),
      okText: okText(secondsToGo),
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDeleteMeeting();
      },
      onCancel() {},
      okButtonProps: {
        disabled: true,
      },
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        okText: okText(secondsToGo),
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.update({
        okText: okText(secondsToGo),
        okButtonProps: {
          disabled: false,
        },
      });
    }, secondsToGo * 1000);
  };

  if (status !== joinStatuses.JOIN_STATUS_OK) {
    return null;
  }

  return (
    <Button onClick={showDeleteConfirm} type="danger">
      {t1('delete_meeting')}
    </Button>
  );
}

export default connect()(EndSession);

EndSession.propTypes = {
  beforeCanJoinToLearn: PropTypes.number,
  searchFormId: PropTypes.string,
  serverTimestamp: PropTypes.number,
  session: PropTypes.object.isRequired,
};

EndSession.defaultProps = {
  session: {},
};
