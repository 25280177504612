import React, { Component } from 'react';
import NodeNew from 'components/admin/node/new';
import sessionSchema from '../schema/form';
import apiUrls from '../endpoints';
import Loading from 'components/common/loading/index';
import { get } from 'common/utils/object';
import { convertToVNTime } from 'common/utils/Date';
import withAutomaticScaleVirtualClass from 'components/admin/session/common/withAutomaticScaleVirtualClass';

class SessionEdit extends Component {
  render() {
    const {
      id,
      teachers = [],
      userIid,
      defaultTeacher = [],
      automaticScaleVirtualClassEnabled,
      viewOnly,
    } = this.props;
    let { node } = this.props;
    const searchFormId = this.props.searchFormId || `edit_session_${id}`;

    // Convert date_time field to VN time to show on date picker
    const scheduledDateTime = get(node, 'scheduled.date_time');
    if (typeof scheduledDateTime === 'number') {
      node = {
        ...node,
        scheduled: {
          ...node.scheduled,
          date_time: scheduledDateTime
            ? convertToVNTime(scheduledDateTime)
            : null,
          learn_duration: node.learn_duration,
        },
      };
    }

    if (!node && !id) {
      return <Loading circularLoadingIcon />;
    }

    return (
      <div>
        <NodeNew
          ntype="session"
          schema={sessionSchema}
          step={''}
          mode={'edit'}
          formid="session-manage"
          closeModal
          alternativeApi={apiUrls.session_update}
          searchFormId={searchFormId}
          node={node}
          userIid={userIid}
          hideSubmitButton={viewOnly}
          teachers={teachers}
          defaultTeacher={defaultTeacher}
          dialogKey="edit_session"
          automaticScaleVirtualClassEnabled={automaticScaleVirtualClassEnabled}
          className="white-background p-10 border-round"
        />
      </div>
    );
  }
}

export default withAutomaticScaleVirtualClass(SessionEdit);
