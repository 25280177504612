// String.prototype.replaceAll = function (str, replace) {
//     reg = new RegExp(RegExp.quote(str), 'g');
//     return this.replace(reg, replace);
// };

// for weird characters
export const uppercaseVietnamese = (str) => {
  const trans = {
    á: 'Á',
    à: 'À',
    ả: 'Ả',
    ã: 'Ã',
    ạ: 'Ạ',
    ă: 'Ă',
    ắ: 'Ắ',
    ằ: 'Ằ',
    ẳ: 'Ẳ',
    ẵ: 'Ẵ',
    ặ: 'Ặ',
    â: 'Â',
    ấ: 'Ấ',
    ầ: 'Ầ',
    ẩ: 'Ẩ',
    ẫ: 'Ẫ',
    ậ: 'Ậ',

    đ: 'Đ',

    é: 'É',
    è: 'È',
    ẻ: 'Ẻ',
    ẽ: 'Ẽ',
    ẹ: 'Ẹ',
    ê: 'Ê',
    ế: 'Ế',
    ề: 'Ề',
    ể: 'Ể',
    ễ: 'Ễ',
    ệ: 'Ệ',

    í: 'I',
    ì: 'Ì',
    ỉ: 'Ỉ',
    ĩ: 'Ĩ',
    ị: 'Ị',

    ó: 'Ó',
    ò: 'Ò',
    ỏ: 'Ỏ',
    õ: 'Õ',
    ọ: 'Ọ',
    ô: 'Ô',
    ố: 'Ố',
    ồ: 'Ồ',
    ổ: 'Ổ',
    ỗ: 'Ỗ',
    ộ: 'Ộ',
    ơ: 'Ơ',
    ớ: 'Ớ',
    ờ: 'Ờ',
    ở: 'Ở',
    ỡ: 'Ỡ',
    ợ: 'Ợ',

    ú: 'Ú',
    ù: 'Ù',
    ủ: 'Ủ',
    ũ: 'Ũ',
    ụ: 'Ụ',
    ư: 'Ư',
    ứ: 'Ứ',
    ừ: 'Ừ',
    ử: 'Ử',
    ữ: 'Ữ',
    ự: 'Ự',

    ý: 'Y',
    ỳ: 'Ỳ',
    ỷ: 'Ỷ',
    ỹ: 'Ỹ',
    ỵ: 'Ỵ',
  };

  let i;
  for (i in trans) str = str.replace(new RegExp(i, 'g'), trans[i]);

  return str.toUpperCase();
};

export const unaccentVietnamese = (str) => {
  const trans = {
    á: 'a',
    à: 'a',
    ả: 'a',
    ã: 'a',
    ạ: 'a',
    ă: 'a',
    ắ: 'a',
    ằ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ặ: 'a',
    â: 'a',
    ấ: 'a',
    ầ: 'a',
    ẩ: 'a',
    ẫ: 'a',
    ậ: 'a',
    Á: 'A',
    À: 'A',
    Ả: 'A',
    Ã: 'A',
    Ạ: 'A',
    Ă: 'A',
    Ắ: 'A',
    Ằ: 'A',
    Ẳ: 'A',
    Ẵ: 'A',
    Ặ: 'A',
    Â: 'A',
    Ấ: 'A',
    Ầ: 'A',
    Ẩ: 'A',
    Ẫ: 'A',
    Ậ: 'A',
    đ: 'd',
    Đ: 'D',
    é: 'e',
    è: 'e',
    ẻ: 'e',
    ẽ: 'e',
    ẹ: 'e',
    ê: 'e',
    ế: 'e',
    ề: 'e',
    ể: 'e',
    ễ: 'e',
    ệ: 'e',
    É: 'E',
    È: 'E',
    Ẻ: 'E',
    Ẽ: 'E',
    Ẹ: 'E',
    Ê: 'E',
    Ế: 'E',
    Ề: 'E',
    Ể: 'E',
    Ễ: 'E',
    Ệ: 'E',
    í: 'i',
    ì: 'i',
    ỉ: 'i',
    ĩ: 'i',
    ị: 'i',
    Í: 'I',
    Ì: 'I',
    Ỉ: 'I',
    Ĩ: 'I',
    Ị: 'I',
    ó: 'o',
    ò: 'o',
    ỏ: 'o',
    õ: 'o',
    ọ: 'o',
    ô: 'o',
    ố: 'o',
    ồ: 'o',
    ổ: 'o',
    ỗ: 'o',
    ộ: 'o',
    ơ: 'o',
    ớ: 'o',
    ờ: 'o',
    ở: 'o',
    ỡ: 'o',
    ợ: 'o',
    Ó: 'O',
    Ò: 'O',
    Ỏ: 'O',
    Õ: 'O',
    Ọ: 'O',
    Ô: 'O',
    Ố: 'O',
    Ồ: 'O',
    Ổ: 'O',
    Ỗ: 'O',
    Ộ: 'O',
    Ơ: 'O',
    Ớ: 'O',
    Ờ: 'O',
    Ở: 'O',
    Ỡ: 'O',
    Ợ: 'O',
    ú: 'u',
    ù: 'u',
    ủ: 'u',
    ũ: 'u',
    ụ: 'u',
    ủ: 'u',
    ư: 'u',
    ứ: 'u',
    ừ: 'u',
    ử: 'u',
    ữ: 'u',
    ự: 'u',
    Ú: 'U',
    Ù: 'U',
    Ủ: 'U',
    Ũ: 'U',
    Ụ: 'U',
    Ư: 'U',
    Ứ: 'U',
    Ừ: 'U',
    Ử: 'U',
    Ữ: 'U',
    Ự: 'U',
    ý: 'y',
    ỳ: 'y',
    ỷ: 'y',
    ỹ: 'y',
    ỵ: 'y',
    Ý: 'Y',
    Ỳ: 'Y',
    Ỷ: 'Y',
    Ỹ: 'Y',
    Ỵ: 'Y',
  };

  let i;
  for (i in trans) str = str.replace(new RegExp(i, 'g'), trans[i]);
  return str;
};
