import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Results from './Results';
import { CourseActions } from 'configs/constants/permission';
import withSchoolConfigs from 'common/hoc/withSchoolConfigs';
import schema from './schema';
import { t1 } from 'translate';
import get from 'lodash.get';
import fetchData from 'components/common/fetchData';
import sessionAPIUrls from '../endpoints';
import { userOrgIids } from 'common/selectors';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  getStudentCourseAPIObject,
  getTeacherCourseAPIObject,
} from '../../common/apiHelper';
import { isFreeze } from 'utils/Util';
import NoResult from '../../../common/search-wrap/NoResult';

const FORM_NAME = 'teacher-course-session-search';

class SessionsLayout extends Component {
  getSelectedCourse = () => {
    const { node, formValues = {}, courseList = [] } = this.props;

    const { course_iid = '' } = formValues;

    const selectedCourse =
      course_iid && courseList.length
        ? courseList.find((course) => course.iid.toString() === course_iid)
        : null;

    return selectedCourse || node;
  };

  checkHasPermission = () => {
    const { hasPermission, permissions } = this.props;

    return true;

    const course = this.getSelectedCourse();

    return course.ntype === 'course'
      ? hasPermission &&
          hasPermission(
            [
              CourseActions.COURSE_ACTION_UPDATE_SESSION,
              CourseActions.COURSE_ACTION_UPDATE,
            ],
            course && course.iid,
            permissions,
          )
      : true;
  };

  renderResultComponent = (items) => {
    const { isStudent, serverTimestamp, noCourseFilter } = this.props;
    const course = this.getSelectedCourse();
    const viewOnly = isFreeze(course);
    const hasPermUpdate = this.checkHasPermission();

    return (
      <Results
        items={items}
        serverTimestamp={serverTimestamp}
        formid={FORM_NAME}
        node={course}
        hasPermUpdate={!isStudent && hasPermUpdate}
        isStudent={isStudent}
        viewOnly={viewOnly}
        noCourseFilter={noCourseFilter}
      />
    );
  };

  renderNoResultComponent = () => {
    const { isStudent } = this.props;
    const course = this.getSelectedCourse();
    const viewOnly = isFreeze(course);
    return (
      <div className="p-15">
        {/* still need to render result component to show up create new session button */}

        {!!course ? (
          <>
            <Results
              items={[]}
              formid={FORM_NAME}
              node={course}
              hasPermUpdate={true}
              isStudent={isStudent}
              viewOnly={viewOnly}
            />
            <NoResult
              text={t1('no_sessions_found')}
              image="/media/images/empty/no-virtual-course.png"
            />
          </>
        ) : (
          <NoResult
            text="Mời bạn chọn lớp"
            image="/media/images/empty/no-virtual-course.png"
          />
        )}
      </div>
    );
  };

  generateHiddenFields = (defaultCourseIid) => {
    const { noCourseFilter } = this.props;

    const hiddenFields = {
      items_per_page: -1,
      _sand_expand: ['scheduled'],
    };

    return noCourseFilter
      ? {
          ...hiddenFields,
          course_iid: defaultCourseIid,
        }
      : hiddenFields;
  };

  render() {
    const {
      node,
      alternativeApi,
      courseList = [],
      isStudent,
      noCourseFilter,
    } = this.props;
    const defaultCourseIid = (node && node.iid) || '';

    return (
      <SearchWrapper
        // key={`${FORM_NAME}_${courseList.length}`}
        formid={FORM_NAME}
        schema={schema}
        hiddenFields={this.generateHiddenFields(defaultCourseIid)}
        renderResultsComponent={this.renderResultComponent}
        renderNoResultComponent={this.renderNoResultComponent}
        alternativeApi={
          alternativeApi || sessionAPIUrls.search_sessions_in_course
        }
        showSearchButton={false}
        autoSearchWhenValuesChange={!noCourseFilter}
        hidePagination={true}
        courseList={courseList}
        node={{
          course_iid: defaultCourseIid.toString(),
        }}
        isStudent={isStudent}
        noCourseFilter={noCourseFilter}
        classFormFilter={noCourseFilter ? 'display-none' : ''}
      />
    );
  }
}

SessionsLayout.propTypes = {
  hiddenFields: PropTypes.instanceOf(Object),
  node: PropTypes.instanceOf(Object),
  isStudent: PropTypes.bool,
  noCourseFilter: PropTypes.bool,
};

SessionsLayout.defaultProps = {
  hiddenFields: null,
  node: null,
  isStudent: false,
  noCourseFilter: false,
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues(FORM_NAME)(state),
    orgIids: userOrgIids(state),
    serverTimestamp: get(state, `searchResults.${FORM_NAME}.server_ts`),
  };
};

const fetchCourseList = fetchData((props) => {
  const { isStudent } = props;
  const organizations = get(props, 'orgIids', []);
  const apiData = isStudent
    ? getStudentCourseAPIObject()
    : getTeacherCourseAPIObject({
        organizations,
        learning_type: ['ilt'],
      });

  return {
    ...apiData,
    propKey: 'courseList',
    keyState: 'session_course_list',
    refetchCondition: (prevProps) =>
      get(prevProps, 'node.iid') !== get(props, 'node.iid'),
  };
});

export default connect(mapStateToProps)(
  withSchoolConfigs(fetchCourseList(SessionsLayout)),
);
