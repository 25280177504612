import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import AntTable from 'antd/lib/table';
import { t, t1 } from 'translate';
import getLodash from 'lodash.get';
import isEqual from 'lodash.isequal';
import AtnDeleteItem from 'components/common/action-button/AtnDeleteBtnWithConfirmDialog';
import { secondsToTimeString } from 'common/utils/Date';
import RaisedButton from 'components/common/mui/RaisedButton';
import { getSessionRoomName } from 'components/timetable_v2/common/sessions';
import RecordingViewer from '../common/Recording';
import CreateSessionsButton from '../new';
import CloneSessionsButton from '../new/Clone';
import Teachers from '../common/Teachers';
import EditButton from '../edit/EditButton';
import ScheduledDateTime from '../common/ScheduledDateTime';
import Status from '../common/Status';
import { canJoinToLearn, joinStatuses } from '../utils';
import { isSmallScreen } from 'common';
import withUserInfo from 'common/hoc/withUserInfo';
import withAutomaticScaleVirtualClass from '../common/withAutomaticScaleVirtualClass';
import fetchData from 'components/common/fetchData';
import './styles.scss';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import PublicCode from '../common/PublicCode';
import apiUrls from 'api-endpoints';
import { compose } from 'redux';
import Alert from 'antd/lib/alert';
import EndSession from '../common/end-session/EndSession';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.sessions, this.props.sessions)) {
      this.onSelectChange([]);
    }
  }

  courseHasEnded = () => {
    const { courseDetail = {} } = this.props;
    const { countDownDeadline } = courseDetail;

    return countDownDeadline <= 0;
  };

  renderButtonDeleteSessions = (selectedRowKeys = []) => {
    const { formid, sessions } = this.props;

    if (!Array.isArray(sessions) || !sessions.length) {
      return null;
    }

    const sessionsStudied = [];
    let warning = false;
    sessions.forEach((session, index) => {
      if (session.status !== 'init') {
        warning = true;
      }

      if (!selectedRowKeys.includes(index)) {
        return;
      }

      if (session.status !== 'init') {
        sessionsStudied.push(session);
      }
    });

    const label = sessionsStudied.length
      ? t1('cannot_delete_(%s_sesions_ended)', [sessionsStudied.length])
      : t1('delete_sessions');

    const isDisabled = !selectedRowKeys.length || sessionsStudied.length;

    return (
      <AtnDeleteItem
        ntype={'session'}
        params={{
          ids: this.getSessionsIdByListIndex(selectedRowKeys),
        }}
        textConfirm={
          warning
            ? t1(
                'system_will_automatically_rearrange_timetables_sessions_according_to_what_will_be_deleted._' +
                  'This_action_may_cause_error_on_some_related_sessions,_therefore_discouraged_to_perform.' +
                  '_Are_you_sure_you_want_to_continue?',
              )
            : null
        }
        formid={formid}
        renderComponent={({ onClick }) => (
          <RaisedButton
            className="session-actions-delete"
            disabled={isDisabled}
            label={label}
            labelPosition="after"
            onClick={onClick}
            buttonType="danger"
          />
        )}
      />
    );
  };

  renderTableActions = (
    session,
    teachers,
    defaultTeacher,
    beforeCanJoinToLearn,
  ) => {
    const {
      formid,
      node,
      hasPermUpdate,
      serverTimestamp,
      automaticScaleVirtualClassEnabled,
      viewOnly,
    } = this.props;

    return (
      <div className="sessions-table-actions">
        {session.type !== 'plan' && hasPermUpdate && !viewOnly && (
          <span>
            <EditButton
              hasPermUpdate={hasPermUpdate}
              session={session}
              formid={formid}
              node={node}
              teachers={teachers}
              defaultTeacher={defaultTeacher}
            />
          </span>
        )}

        <RecordingViewer
          session={session}
          serverTimestamp={serverTimestamp}
          automaticScaleVirtualClassEnabled={automaticScaleVirtualClassEnabled}
          viewOnly={viewOnly}
        />

        {session.status === 'init' && hasPermUpdate && !viewOnly && (
          <span>
            <AtnDeleteItem
              ntype={'session'}
              itemId={session.id}
              formid={formid}
              iconButton
              icon={'ve-delete'}
            />
          </span>
        )}
        <EndSession
          session={session}
          beforeCanJoinToLearn={beforeCanJoinToLearn}
          serverTimestamp={serverTimestamp}
          searchFormId={formid}
        />
      </div>
    );
  };

  getSessionsIdByListIndex = (sessionsIndex = []) => {
    const { sessions } = this.props;

    if (!Array.isArray(sessions) || !sessions.length) {
      return [];
    }

    const sessionsSelected = [];
    sessions.forEach((session, index) => {
      if (!sessionsIndex.includes(index)) {
        return;
      }

      sessionsSelected.push(session);
    });

    return sessionsSelected.map((session) => session.id);
  };

  getDefaultTeacher = () => {
    const { userInfo, teachers = [] } = this.props;
    let defaultTeacher = [];
    const userIid = getLodash(userInfo, 'iid');
    if (teachers.length > 1) {
      defaultTeacher = teachers.filter(
        (teacher) => String(teacher.iid) === String(userIid),
      );
    } else {
      defaultTeacher = teachers;
    }
    return defaultTeacher;
  };

  renderWarning = () => {
    return (
      <div className="m-b-15">
        <Alert
          type="error"
          message={t1('course_has_ended')}
          description={t1(
            'course_has_ended_you_can_not_create_or_clone_sessions',
          )}
          showIcon
        />
      </div>
    );
  };

  render() {
    const {
      sessions,
      hasPermUpdate,
      beforeCanJoinToLearn,
      node,
      formid,
      isStudent,
      serverTimestamp,
      automaticScaleVirtualClassEnabled,
      teachers = [],
      viewOnly,
      courseDetail,
      noCourseFilter,
    } = this.props;

    const courseEnded = this.courseHasEnded();
    const defaultTeacher = this.getDefaultTeacher();

    if (!Array.isArray(sessions) || !sessions.length) {
      return (
        <div className="sessions-wrapper">
          {courseEnded && this.renderWarning()}
          <div className="session-actions-left">
            {!isStudent && (
              <CreateSessionsButton
                hasPermUpdate={hasPermUpdate}
                node={courseDetail}
                formid={formid}
                teachers={teachers}
                defaultTeacher={defaultTeacher}
                viewOnly={viewOnly || courseEnded}
              />
            )}
          </div>
        </div>
      );
    }

    const order = isStudent
      ? {
          title: 'STT',
          key: 'stt',
          type: TYPE_OF_TABLE_DATA.TEXT,
          width: 65,
          render: (text, session, index) => (
            <span className="color-by-status">{index + 1}</span>
          ),
        }
      : null;
    const sessionName = {
      title: t1('session_name'),
      key: 'id',
      rowKey: 'id',
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 170,
      render: (text, session) => (
        <div className="color-by-status">
          <span>{session && session.name}</span>
          <div>{session.course_name ? `(${session.course_name})` : ''}</div>
        </div>
      ),
    };
    const gotoSession = {
      title: t1('join_live_session'),
      key: 'virtual_course_join',
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 150,
      render: (text, session) => (
        <Status
          session={session}
          beforeCanJoinToLearn={beforeCanJoinToLearn}
          serverTimestamp={serverTimestamp}
          automaticScaleVirtualClassEnabled={
            session.automaticScaleVirtualClassEnabled ||
            automaticScaleVirtualClassEnabled
          }
        />
      ),
    };
    const schedule = {
      title: t1('session_schedule'),
      key: 'session_schedule',
      type: TYPE_OF_TABLE_DATA.DATE,
      width: 155,
      render: (text, session) => {
        return (
          <div className="color-by-status">
            <ScheduledDateTime session={session} />
          </div>
        );
      },
    };
    const duration = {
      title: `${t1('session_duration')} (${t('hh:mm')})`, //(giờ:phút)',
      key: 'session_duration',
      type: TYPE_OF_TABLE_DATA.DATE,
      width: 100,
      render: (text, session) => ({
        children: (
          <div className="color-by-status">
            {(session.learn_duration > 0 || session.learn_duration > 0) &&
              `${secondsToTimeString(
                session.learn_duration || session.duration || 0,
              )}`}
            {session.break_time > 0 && [
              session.learn_duration > 0 || session.learn_duration > 0 ? (
                <br />
              ) : null,
              `${t1('break_time')}: ${secondsToTimeString(session.break_time)}`,
            ]}
          </div>
        ),
      }),
    };
    const location = {
      title: t1('session_location'),
      key: 'session_location',
      width: 155,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (text, session) => {
        return (
          <div className="color-by-status">{getSessionRoomName(session)}</div>
        );
      },
    };
    const instructor = {
      title: t1('session_instructors'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 170,
      render: (text, session) => {
        return (
          <div className="color-by-status">
            <Teachers session={session} />
          </div>
        );
      },
    };
    const actions = {
      title: `${isStudent ? t1('replay') : t1('session_actions')}`,
      key: 'virtual_course_actions',
      type: TYPE_OF_TABLE_DATA.ACTION,
      width: hasPermUpdate ? 130 : 110,
      render: (text, session) =>
        this.renderTableActions(
          session,
          teachers,
          defaultTeacher,
          beforeCanJoinToLearn,
          serverTimestamp,
        ),
    };
    const publicCode = {
      title: t1('public_code'),
      key: 'public_code',
      type: TYPE_OF_TABLE_DATA.TEXT,
      width: 150,
      align: 'center',
      render: (code, session) => <PublicCode session={session} />,
    };

    const columns = (isSmallScreen
      ? [
          null,
          schedule,
          publicCode,
          gotoSession,
          sessionName,
          duration,
          instructor,
          actions,
        ]
      : [
          order,
          sessionName,
          schedule,
          duration,
          instructor,
          gotoSession,
          publicCode,
          actions,
        ]
    ).filter(Boolean);

    return (
      <div
        className={`sessions-wrapper ${!noCourseFilter ? 'p-l-15 p-r-15' : ''}`}
      >
        {courseEnded && this.renderWarning()}

        {hasPermUpdate && !viewOnly && (
          <div className="session-actions m-b-20">
            <div className="session-actions-left">
              <CreateSessionsButton
                hasPermUpdate={hasPermUpdate}
                node={courseDetail}
                formid={formid}
                teachers={teachers}
                defaultTeacher={defaultTeacher}
                viewOnly={courseEnded}
              />
              <CloneSessionsButton
                hasPermUpdate={hasPermUpdate}
                node={node}
                formid={formid}
                session_ids={this.getSessionsIdByListIndex(
                  this.state.selectedRowKeys,
                )}
                viewOnly={courseEnded}
              />
            </div>

            <div className="session-actions-right">
              {this.renderButtonDeleteSessions(this.state.selectedRowKeys)}
            </div>
          </div>
        )}

        <AntTable
          className={'learn-default white-background'}
          columns={columns}
          dataSource={sessions}
          bordered
          pagination={false}
          rowClassName={(session) => {
            const status = canJoinToLearn(
              session,
              beforeCanJoinToLearn,
              serverTimestamp,
              automaticScaleVirtualClassEnabled ||
                session.automaticScaleVirtualClassEnabled,
            );
            switch (status) {
              case joinStatuses.JOIN_STATUS_OK:
                return 'join-status-ok';
              case joinStatuses.JOIN_STATUS_TOO_LATE:
                return 'join-status-too-late';
              case joinStatuses.JOIN_STATUS_TOO_EARLY:
                return 'join-status-too-early';
            }
          }}
          rowSelection={
            hasPermUpdate
              ? {
                  selectedRowKeys: this.state.selectedRowKeys || [],
                  onChange: this.onSelectChange,
                }
              : null
          }
          scroll={{
            x: true,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => getLodash(state, 'domainInfo.conf.before_time_can_join_class'),
  (state) => getLodash(state, 'domainInfo.school.supported_learning_methods'),
  (state, props) => {
    const { items } = props;
    if (!Array.isArray(items) || !items.length) {
      return [];
    }

    return items
      .map((session) => {
        const item = getLodash(state, `tree.${session.iid}`) || session;
        return { ...session, item };
      })
      .filter(Boolean);
  },
  (beforeCanJoinToLearn, supportedLearningMethods, sessions) => ({
    beforeCanJoinToLearn,
    supportedLearningMethods,
    sessions,
  }),
);

const fetchCourseDetail = fetchData((props) => {
  const courseIid = getLodash(props, 'node.iid', '');
  return {
    baseUrl: apiUrls.get_course_api,
    fetchCondition: !!courseIid,
    params: {
      ciid: courseIid,
    },
    propKey: 'courseDetail',
  };
});

const fetchListTeacher = fetchData((props) => {
  const course_iid = getLodash(props, 'node.iid');
  const isStudent = getLodash(props, 'isStudent');
  return {
    baseUrl: `/course/session/get-teacher-list?iid=${course_iid}`,
    propKey: 'teachers',
    fetchCondition: !isStudent && !!course_iid,
    refetchCondition: (prevProps) =>
      course_iid &&
      String(getLodash(prevProps, 'node.iid')) !== String(course_iid),
  };
});

export default compose(
  connect(mapStateToProps),
  fetchCourseDetail,
  fetchListTeacher,
  withUserInfo,
  withAutomaticScaleVirtualClass,
)(Results);
