import React, { Component } from 'react';
import { t1 } from 'translate/index';
import { canJoinToLearn, isSessionScheduled, joinStatuses } from '../utils';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import apiUrls from '../endpoints/index';
import Request from 'common/network/http/Request';
import { timestampToDateString } from 'common/utils/Date';
import actions from 'actions/node/creators';
import './stylesheet.scss';

class SessionRecordingViewer extends Component {
  getRecording = (sessionIid) => {
    const params = {
      session_iid: sessionIid,
      is_course_admin: 1,
    };
    const { dispatch } = this.props;

    Request.post(apiUrls.get_session_recording, params).then((res) => {
      let contentDialog;
      if (res.success && Array.isArray(res.result) && res.result.length) {
        contentDialog = (
          <div>
            {res.result.map((recording, idx) => {
              return (
                <div key={recording.recordID}>
                  <a
                    href={lodashGet(recording, 'playback.format.url')}
                    target="_blank"
                  >
                    #{idx + 1}. <b>{lodashGet(recording, 'participants')}</b>{' '}
                    {t1('participants')}
                    <span className="m-l-20">
                      {timestampToDateString(
                        Math.floor(lodashGet(recording, 'startTime') / 1000),
                        { showTime: true },
                      )}{' '}
                      -{' '}
                      {timestampToDateString(
                        Math.floor(lodashGet(recording, 'endTime') / 1000),
                        { showTime: true },
                      )}{' '}
                    </span>
                  </a>
                </div>
              );
            })}
          </div>
        );
      } else {
        contentDialog = (
          <div>{t1('no_recording_rendered_yet_please_wait_a_while')}</div>
        );
      }

      const optionsProperties = {
        handleClose: true,

        modal: true,
        title: t1('playback_session_recordings'),
      };
      dispatch(actions.handleOpenDialog({ contentDialog, optionsProperties }));
    });
  };

  render() {
    const {
      session,
      beforeTimeCanJoinClass,
      text,
      serverTimestamp,
    } = this.props;

    const status = canJoinToLearn(
      session,
      beforeTimeCanJoinClass,
      serverTimestamp,
    );
    const cannotPlay =
      !session.enable_recording ||
      !isSessionScheduled(session) ||
      status === joinStatuses.JOIN_STATUS_TOO_EARLY ||
      status === joinStatuses.JOIN_STATUS_NOT_YET_SCHEDULED ||
      status === joinStatuses.JOIN_STATUS_OK;

    return (
      <span
        onClick={() => {
          if (cannotPlay) {
            return;
          }

          this.getRecording(session.iid);
        }}
        className={`ve-play play-icon ${
          cannotPlay ? 'cannot-play-recorded' : 'can-play-recorded'
        }`}
      >
        {!!text && text}
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    beforeTimeCanJoinClass: lodashGet(
      state,
      'domainInfo.conf.before_time_can_join_class',
    ),
  };
};

export default connect(mapStateToProps)(SessionRecordingViewer);
