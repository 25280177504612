import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Created by Peter Hoang Nguyen on 4/16/2017.
 */
export const settingIcon =
  'm501 213l-61 0c-4-17-9-35-16-49l43-44c2-2 3-4 3-7c0-3-1-6-3-8l-60-60c-4-4-11-4-15 ' +
  '0l-44 43c-14-7-32-12-49-16l0-61c0-6-5-11-11-11l-64 0c-6 0-11 5-11 11l0 61c-17 4-35 9-49 ' +
  '16l-44-43c-4-4-11-4-15 0l-60 60c-4 4-4 11 0 15l43 44c-7 14-12 32-16 49l-61 0c-6 0-11 5-11' +
  ' 11l0 64c0 6 5 11 11 11l61 0c4 17 9 35 16 49l-43 44c-2 2-3 4-3 7c0 3 1 6 3 8l60 60c4 4 11 ' +
  '4 15 0l44-43c14 7 32 12 49 16l0 61c0 6 5 11 11 11l64 0c6 0 11-5 11-11l0-61c17-4 35-9 ' +
  '49-16l44 43c4 4 11 4 15 0l60-60c4-4 4-11 0-15l-43-44c7-14 12-32 16-49l61 0c6 0 11-5 ' +
  '11-11l0-64c0-6-5-11-11-11z m-10 64l-59 0c-5 0-9 4-10 8c-3 12-11 44-20 60c-3 4-2 9 1 ' +
  '13l42 41l-46 46l-41-42c-4-3-9-4-13-1c-16 9-48 17-60 20c-4 1-8 5-8 10l0 59l-42 ' +
  '0l0-59c0-5-4-9-8-10c-12-3-44-11-60-20c-4-3-9-2-13 1l-41 42l-46-46l42-41c3-4 4-9 ' +
  '1-13c-9-16-17-48-20-60c-1-4-5-8-10-8l-59 0l0-42l59 0c5 0 9-4 10-8c3-12 11-44 20-60c3-4' +
  ' 2-9-1-13l-42-41l46-46l41 42c4 3 9 4 13 1c16-9 48-17 59-20c5-1 9-5 9-10l0-59l42 0l0 59c0 ' +
  '5 4 9 8 10c19 5 46 12 60 20c4 3 9 2 13-1l41-42l46 46l-42 41c-3 4-4 9-1 13c9 16 17 48 20 59c1 ' +
  '5 5 9 10 9l59 0z m-235-128c-59 0-107 48-107 107c0 59 48 107 107 107c59 0 107-48 ' +
  '107-107c0-59-48-107-107-107z m0 192c-47 0-85-38-85-85c0-47 38-85 85-85c47 0 85 38 85 ' +
  '85c0 47-38 85-85 85z';
export const profileIcon =
  'm256 0c-141 0-256 115-256 256c0 68 26 132 74 180c49 49 113 76 182 76c69 0 ' +
  '134-27 182-76c0 0 0 0 0 0c48-48 74-112 74-180c0-141-115-256-256-256z m0 ' +
  '491c-59 0-114-22-157-61c22-12 54-23 86-35c11-4 22-8 32-12c4-1 7-5 7-10l0-53c0-5-3-9-7-10c-1' +
  ' 0-25-10-25-54c0-6-4-11-10-11c-1 0-3-4-3-10c0-6 2-10 2-11c6 0 11-5 ' +
  '11-11c0-2-1-5-3-11c-3-11-11-41-4-51c1-1' +
  ' 3-3 8-3c3 0 5 1 8 1c5 1 11-2 12-8c3-14 28-24 59-24c31 0 56 10 59 24c5 20-4 47-8 60c-2 ' +
  '6-3 9-3 12c0 6 4 11 10 11c1 1 3 5 3 11c0 6-2 10-2 10c-6 0-11 5-11 11c0 44-24 54-25 54c-4 ' +
  '1-7 5-7 10l0 53c0 5 3 9 7 10c11 5 23 9 34 13c32 12 63 23 84 34c-43 39-98 61-157 61z ' +
  'm173-76c-22-14-55-26-92-39c-9-3-19-7-28-10l0-39c11-7 30-24 32-63c8-5 14-16 ' +
  '14-29c0-13-5-23-12-28c6-16 15-46 9-71c-8-30-48-40-80-40c-29 0-64 8-76 31c-15-1-23 ' +
  '6-28 11c-13 18-4 51 1 69c-7 5-12 15-12 28c0 13 6 24 14 29c2 39 21 56 32 63l0 39c-9 ' +
  '3-17 6-25 9c-35 13-71 26-95 40c-40-44-62-100-62-159c0-129 106-235 235-235c129 0 235 ' +
  '106 235 235c0 59-22 115-62 159z';
export const upgradeIcon =
  'm488 24c-3-2-6-3-10-3c-9 2-94 17-119 41c-7 8-71 72-132 133c-32 0-78 0-98 7c-17 6-38 23-49 ' +
  '34l-75 75c-3 3-4 8-2 12c2 4 5 6 9 6c1 0 1 0 2 0l94-11c-1 3 0 6 3 8l75 76c2 2 5 3 7 3c1 0 1 0' +
  ' 1 0l-11 93c-1 5 2 9 6 11c1 1 3 1 4 1c3 0 6-1 8-3l75-75c10-10 27-32 33-50c5-15 8-48 ' +
  '8-97l133-132c24-25 39-110 41-119c0-4-1-7-3-10z m-393 227c11-11 29-25 41-29c11-3 34-5 ' +
  '70-6c-24 24-47 47-64 64c0 0-1 0-1 1c-1 0-1 1-2 2c-4 4-7 7-11 11l-86 10z m38 68c4-4 9-9 ' +
  '15-15l60 60l-15 15z m156 57c-4 11-18 31-28 41l-53 53l10-85l13-14c0 0 0 0 0 0c0 0 0 0 0 ' +
  '0l65-64c-1 44-5 62-7 69z m146-238l-211 211l-61-60c67-67 200-200 211-212c13-12 59-25 93-32c-7 ' +
  '34-20 80-32 93z m-294 248c-5 5-5 11 0 16c14 14-4 34-8 37c-12 12-61 28-98 38c10-37 26-86 38-98c3-4 ' +
  '23-22 37-8c5 5 11 5 15 0c5-4 5-11 0-15c-18-18-45-15-67 8c-23 22-46 115-48 126c-1 3 0 7 2 10c2 2 ' +
  '5 3 8 3c1 0 2 0 2 0c11-3 104-26 126-49c23-22 26-49 8-68c-5-4-11-4-15 0z';
export const helpIcon =
  'm501 213l-14 0c-18-95-93-170-188-188l0-14c0-6-5-11-11-11l-64 0c-6 0-11 5-11 11l0 14c-95 ' +
  '18-170 93-188 188l-14 0c-6 0-11 5-11 11l0 64c0 6 5 11 11 11l14 0c18 95 93 170 188 188l0 ' +
  '14c0 6 5 11 11 11l64 0c6 0 11-5 11-11l0-14c95-18 170-93 188-188l14 0c6 0 11-5 ' +
  '11-11l0-64c0-6-5-11-11-11z m-36 0l-100 0c-12-30-36-54-66-66l0-100c83 17 149 83 ' +
  '166 166z m-209 139c-53 0-96-43-96-96c0-53 43-96 96-96c53 0 96 43 96 96c0 53-43 ' +
  '96-96 96z m21-331l0 120c-7-2-14-2-21-2c-7 0-14 0-21 2l0-120z m-64 26l0 100c-30 ' +
  '12-54 36-66 66l-100 0c17-83 83-149 166-166z m-192 188l120 0c-2 7-2 14-2 21c0 7 ' +
  '0 14 2 21l-120 0z m26 64l100 0c12 30 36 54 66 66l0 100c-83-17-149-83-166-166z m188 ' +
  '192l0-120c7 2 14 2 21 2c7 0 14 0 21-2l0 120z m64-26l0-100c30-12 54-36 66-66l100 0c-17' +
  ' 83-83 149-166 166z m192-188l-120 0c2-7 2-14 2-21c0-7 0-14-2-21l120 0z';
export const logoutIcon =
  'm288 385c0 0 0 0 0 0c-6 0-10 4-10 10l-1 75l-256-1l0-426l257 0l0 75c0 6 5 11 11 ' +
  '11c0 0 0 0 0 0c6 0 10-5 10-11l1-85c0-6-5-11-11-11l-278-1c0 0 0 0 0 0c-3 0-6 1-8 ' +
  '3c-2 2-3 5-3 8l0 448c0 6 5 11 11 11l277 0l0 0c6 0 11-5 11-10l0-86c0-6-5-10-11-10z ' +
  'm223-122c-1-2-2-4-4-5l-104-105c-4-4-11-4-15 0c-4 4-4 11 0 15l87 88l-357 0c-6 0-11 ' +
  '5-11 11c0 6 5 11 11 11l359-1l-90 90c-4 4-4 10 0 15c2 2 5 3 8 3c3 0 5-1 7-3l107-107c2-2' +
  ' 3-5 3-7c0-2 0-4-1-5z';
export const searchIcon =
  'm509 494l-192-192c0 0 0 0 0 0c28-32 46-74 46-121c0-100-82-181-182-181c-100 0-181 ' +
  '81-181 181c0 100 81 182 181 182c47 0 89-18 121-46c0 0 0 0 0 0l192 192c2 2 5 3 7 ' +
  '3c3 0 6-1 8-3c4-4 4-11 0-15z m-328-153c-88 0-160-71-160-160c0-88 72-160 160-160c89' +
  ' 0 160 72 160 160c0 89-71 160-160 160z';
export const collectionIcon =
  'm437 0l-384 0c-29 0-53 24-53 53l0 384c0 30 24 54 53 54l384 0c30 0 54-24 ' +
  '54-54l0-384c0-29-24-53-54-53z m32 437c0 18-14 32-32 32l-384 0c-17 0-32-14-32-32l0-384c0-17 ' +
  '15-32 32-32l384 0c18 0 32 15 32 32z m-309-373l-85 0c-6 0-11 5-11 11l0 85c0 6 5 11 11 11l85 ' +
  '0c6 0 11-5 11-11l0-85c0-6-5-11-11-11z m-11 85l-64 0 0-64 64 0z m139-85l-85 0c-6 0-11 5-11 ' +
  '11l0 85c0 6 5 11 11 11l85 0c6 0 11-5 11-11l0-85c0-6-5-11-11-11z m-11 85l-64 0 0-64 64 0z ' +
  'm139-85l-85 0c-6 0-11 5-11 11l0 85c0 6 5 11 11 11l85 0c6 0 11-5 11-11l0-85c0-6-5-11-11-11z ' +
  'm-11 85l-64 0 0-64 64 0z m-245 43l-85 0c-6 0-11 5-11 11l0 85c0 6 5 11 11 11l85 0c6 0 11-5 ' +
  '11-11l0-85c0-6-5-11-11-11z m-11 85l-64 0 0-64 64 0z m139-85l-85 0c-6 0-11 5-11 11l0 85c0 6 ' +
  '5 11 11 11l85 0c6 0 11-5 11-11l0-85c0-6-5-11-11-11z m-11 85l-64 0 0-64 64 0z m139-85l-85 0c-6 ' +
  '0-11 5-11 11l0 85c0 6 5 11 11 11l85 0c6 0 11-5 11-11l0-85c0-6-5-11-11-11z m-11 85l-64 0 0-64 64' +
  ' 0z m-245 43l-85 0c-6 0-11 5-11 11l0 85c0 6 5 11 11 11l85 0c6 0 11-5 11-11l0-85c0-6-5-11-11-11z' +
  ' m-11 85l-64 0 0-64 64 0z';
export const additionalIcon =
  'm491 235l-213 0l0-214c0-11-10-21-21-21c0 0 0 0 0 0c-12 0-22 10-22 21l0 214l-214 0c-11 0-21 9-21 ' +
  '21c0 12 10 21 21 21l214 0l0 214c0 11 9 21 21 21l0 0c12 0 21-10 21-21l1-214l213 0c12 0 21-9 ' +
  '21-21c0-12-9-21-21-21z';
export const downloadIcon =
  'm245 0c-135 0-245 110-245 245c0 136 110 246 245 246c136 0 246-110 246-246c0-135-110-245-246-245z m0 469c-123 0-224-100-224-224c0-123 101-224 224-224c124 0 224 101 224 224c0 124-100 224-224 224z m110-210l-99 99l0-251c0-6-5-11-11-11c-6 0-10 5-10 11l0 251l-99-99c-5-4-11-4-16 0c-4 4-4 11 0 15l118 118c1 1 2 1 3 2c2 0 3 1 4 1c2 0 3-1 4-1c2-1 3-1 4-2l117-118c4-4 4-11 0-15c-4-4-11-4-15 0z';
export const shareIcon =
  'm512 22c0-1 0-2 0-2c0-1 0-1-1-2c0-1 0-1 0-2c-1 0-1 0-1 0c0-1 0-1 0-1c-1-1-1-1-2-1c0-1 0-1-1-2c0 0 0 0 0 0c-1 0-1 0-1 0c-1-1-2-1-2-1c-1 0-1 0-2 0c0 0-1 0-2 0c0 0-1 0-2 0c0 0 0 0-1 0l-490 203c-4 2-7 6-7 10c0 4 3 8 7 10l185 72l0 163c0 5 3 9 8 11c1 0 2 0 3 0c3 0 7-2 9-5l70-121l129 71c1 1 3 2 5 2c2 0 3-1 4-1c3-2 6-4 6-8l86-394c0-1 0-1 0-1c0 0 0-1 0-1z m-61 32l-251 232l-161-62z m-238 376l0-113l50 27z m196-30l-188-104l263-244z';
export const pictureIcon =
  'm501 0l-490 0c-6 0-11 5-11 11l0 490c0 6 5 11 11 11l490 0c6 0 11-5 11-11l0-490c0-6-5-11-11-11z m-10 21l0 342-15 0-73-145c-7-16-23-26-41-26-17 0-32 9-40 23l-57 97c-16-20-52-56-94-56-53 0-92 81-103 107l-47 0 0-342z m-39 342l-361 0c16-33 47-86 80-86 47 0 86 59 87 60 2 3 5 4 9 4 4 0 7-2 9-5l64-110c5-8 13-13 22-13 9 0 18 6 22 14z m-431 128l0-107 470 0 0 107z m118-299l64 0c35 0 64-29 64-64 0-35-29-64-64-64-28 0-52 17-61 43-24-2-46 18-46 42 0 24 19 43 43 43z m0-64c2 0 5 0 7 2 3 1 7 0 10-1 2-2 4-5 5-8 3-21 21-36 42-36 23 0 42 19 42 43 0 24-19 43-42 43l-64 0c-12 0-22-10-22-22 0-11 10-21 22-21z';
export const arrowIcon =
  'm149 256l261-218c9-8 10-21 3-30c-8-9-21-11-30-3l-281 235c-5 4-8 10-8 16c0 6 3 12 8 16l280 235c4 3 9 5 14 5c6 0 12-3 17-8c7-9 6-22-3-30z';
export const arrowMoveLeft =
  'm245 235l-209 0l68-67c4-5 4-11 0-16c-5-4-11-4-16 0l-85 86c-1 1-2 2-2 3c-1 3-1 6 0 8c0 2 1 3 2 4l85 85c3 2 5 3 8 3c3 0 5-1 8-3c4-4 4-11 0-15l-68-67l209 0c6 0 11-5 11-11c0-6-5-10-11-10z m256-235l-149 0c-6 0-11 5-11 11l0 490c0 6 5 11 11 11l149 0c6 0 11-5 11-11l0-490c0-6-5-11-11-11z m-10 491l-128 0l0-470l128 0z';
export const previewIcon =
  'm510 271c-5-6-115-143-254-143c-139 0-249 137-254 143c-3 4-3 9 0 13c5 6 115 143 254 143c139 0 249-137 254-143c3-4 3-9 0-13z m-254 134c-112 0-208-101-231-128c23-27 119-128 231-128c112 0 208 101 231 128c-23 27-119 128-231 128z m0-224c-53 0-96 43-96 96c0 53 43 96 96 96c53 0 96-43 96-96c0-53-43-96-96-96z m0 171c-41 0-75-33-75-75c0-41 34-74 75-74c41 0 75 33 75 74c0 42-34 75-75 75z m0-128c-6 0-11 5-11 11c0 6 5 10 11 10c18 0 32 15 32 32c0 18-14 32-32 32c-18 0-32-14-32-32c0-6-5-10-11-10c-6 0-10 4-10 10c0 30 24 54 53 54c29 0 53-24 53-54c0-29-24-53-53-53z';
export const rejectIcon =
  'm512 160c0 0 0 0 0 0c0 0 0 0 0 0c0-1 0-3-1-4c0 0 0-1 0-1c-1 0-1-1-1-1l-107-150c0 0-1-1-2-2c0 0 0 0 0 0c-1-1-2-1-3-1c0 0 0-1 0-1c0 0 0 0-1 0c0 0-1 0-2 0l-278 0c-1 0-2 0-2 0c-1 0-1 0-1 0c0 0 0 1 0 1c-1 0-2 0-3 1c0 0 0 0 0 0c-1 1-2 2-2 2l-107 150c0 0 0 1-1 1c0 0 0 1 0 1c-1 3-1 5 0 8c0 0 0 0 0 1c1 0 1 1 1 1l245 342c1 0 2 1 2 2c1 0 1 0 1 0c1 1 1 1 2 1c0 0 1 0 1 0c1 1 2 1 3 1c1 0 2 0 3-1c0 0 1 0 1 0c1 0 1 0 2-1c0 0 0 0 1 0c0-1 1-2 2-2l0-1c0 0 0 0 0 0l245-341c0 0 0-1 1-1c0-1 0-1 0-1c1-1 1-3 1-4z m-399-125l33 114l-115 0z m225 136l-82 291l-82-291z m-158-22l76-119l76 119z m96-128l105 0l-33 113z m-112 113l-33-113l105 0z m-12 37l77 275l-198-275z m208 0l121 0l-198 275z m6-22l33-114l82 114z';

/**
 * Created by Peter Hoang Nguyen
 * Email: vntopmas@gmail.com
 * Tel: 0966298666
 * created date 16/04/2017
 **/
class SvgIcon extends Component {
  render() {
    const { className, path, viewBox } = this.props;
    return (
      <svg className={className} viewBox={viewBox}>
        <path d={path} />
      </svg>
    );
  }
}

SvgIcon.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  viewBox: PropTypes.string,
};
SvgIcon.defaultProps = {
  className: '',
  path: helpIcon,
  viewBox: '0 0 512 512',
};
export default SvgIcon;
