import React from 'react';

import { courseFilter } from '../../common/schemaFormField';

const schema = (formid, values, step, xpath, props) => {
  const { courseList = [], isStudent } = props;

  return {
    course_iid: courseFilter(courseList, isStudent, !isStudent),
  };
};

const ui = (step, values, themeConfig, xpath, formid, props) => {
  return [
    {
      id: 'default',
      fields: [...(props.noCourseFilter ? [] : ['course_iid'])],
    },
  ];
};

const Layout = ({ groups }) => (
  <div className="row">
    <div className="col-md-8">{groups.default.fieldNames.course_iid}</div>
  </div>
);

export default {
  schema,
  ui,
};
