import React, { useEffect, useState } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import get from 'lodash.get';

function withCourseHeaderHight(WrappedComponent) {
  function CourseHeaderHightHOC(props) {
    const [headerHeight, setHeaderHeight] = useState(0);
    useEffect(
      () => {
        setHeaderHeight(
          get(
            document.getElementById('learn-course-header'),
            'clientHeight',
            0,
          ),
        );
      },
      [props.courseIid],
    );

    return <WrappedComponent headerHeight={headerHeight} {...props} />;
  }

  hoistNonReactStatic(CourseHeaderHightHOC, WrappedComponent);
  return CourseHeaderHightHOC;
}

export default withCourseHeaderHight;
