import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import RaisedButton from 'components/common/mui/RaisedButton';
import FormCreateSession from './Create';
import { isSmallScreen } from 'common';
import { ILT_BBB } from '../common/constants';

class CreateNewSessions extends Component {
  render() {
    const {
      formid,
      node = {},
      hasPermUpdate,
      teachers = [],
      defaultTeacher = [],
      viewOnly = false,
    } = this.props;
    if (!hasPermUpdate || !node.iid) {
      return null;
    }
    return (
      <DetailOnDialog
        renderPreview={({ showFull }) => (
          <RaisedButton
            className={`m-r-10 ${isSmallScreen ? 'm-b-10' : ''}`}
            label={t1('create_class_sessions')}
            labelPosition="after"
            onClick={showFull}
            primary
            disabled={viewOnly}
          />
        )}
        renderFull={() => (
          <FormCreateSession
            searchFormId={formid}
            hiddenFields={{
              course_iid: node && node.iid,
              type: node && node.ntype === 'syllabus' ? 'plan' : '',
              location: ILT_BBB,
              count: 1,
            }}
            node={node}
            teachers={teachers}
            defaultTeacher={defaultTeacher}
          />
        )}
        dialogOptionsProperties={{
          modal: true,
          width: isSmallScreen ? '100%' : '80%',
          handleClose: true,
          title: `${t1('add_a_new_session')}: ${node && node.name}`,
          className: `modal-new-virtual-classes ${
            isSmallScreen ? 'modal-full-height' : ''
          }`,
        }}
        dialogKey="session-manage"
      />
    );
  }
}

export default connect()(CreateNewSessions);
